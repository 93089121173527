import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

function Register() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setError('');
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        if (formData.password !== formData.confirmPassword) {
            setError('Die Passwörter stimmen nicht überein');
            setIsLoading(false);
            return;
        }

        try {
            await axios.post('https://blemds.dev/api/register', {
                username: formData.username,
                password: formData.password
            });
            navigate('/');
        } catch (err) {
            setError(err.response && err.response.data && err.response.data.message || 'Ein Fehler ist aufgetreten');
        } finally {
            setIsLoading(false);
        }
    };

    return ( <
        div className = "auth-container" >
        <
        div className = "auth-card" >
        <
        div className = "auth-header" >
        <
        h2 > 🎯Anatomie App < /h2> <
        p > Erstelle dein Konto < /p> < /
        div >

        <
        form onSubmit = { handleSubmit }
        className = "auth-form" >
        <
        div className = "form-group" >
        <
        label htmlFor = "username" >
        <
        span className = "input-icon" > 👤 < /span>
        Benutzername <
        /label> <
        input type = "text"
        id = "username"
        name = "username"
        value = { formData.username }
        onChange = { handleChange }
        placeholder = "Wähle einen Benutzernamen"
        required autoFocus /
        >
        <
        /div>

        <
        div className = "form-group" >
        <
        label htmlFor = "password" >
        <
        span className = "input-icon" > 🔒 < /span>
        Passwort <
        /label> <
        input type = "password"
        id = "password"
        name = "password"
        value = { formData.password }
        onChange = { handleChange }
        placeholder = "Wähle ein sicheres Passwort"
        required /
        >
        <
        /div>

        <
        div className = "form-group" >
        <
        label htmlFor = "confirmPassword" >
        <
        span className = "input-icon" > 🔐 < /span>
        Passwort bestätigen <
        /label> <
        input type = "password"
        id = "confirmPassword"
        name = "confirmPassword"
        value = { formData.confirmPassword }
        onChange = { handleChange }
        placeholder = "Passwort wiederholen"
        required /
        >
        <
        /div>

        {
            error && < div className = "error-message" > ❌{ error } < /div>}

            <
            button
            type = "submit"
            className = { `auth-button ${isLoading ? 'loading' : ''}` }
            disabled = { isLoading } > {
                    isLoading ? ( <
                        span className = "loading-spinner" > ⌛ < /span>
                    ) : (
                        'Registrieren'
                    )
                } <
                /button> < /
            form >

                <
                div className = "auth-footer" >
                <
                p > Bereits registriert ? < /p> <
            Link to = "/"
            className = "auth-link" >
                Zum Login🎮 <
                /Link> < /
            div > <
                /div> < /
            div >
        );
    }

    export default Register;