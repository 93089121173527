import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Profile() {
    const [userData, setUserData] = useState(null);
    const [achievements, setAchievements] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async() => {
            try {
                const token = localStorage.getItem('token');
                const decoded = JSON.parse(atob(token.split('.')[1]));

                const [userResponse, achievementsResponse] = await Promise.all([
                    axios.get(`https://blemds.dev/api/user/${decoded.id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    }),
                    axios.get(`https://blemds.dev/api/user-achievements/${decoded.id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                ]);

                setUserData(userResponse.data);
                setAchievements(achievementsResponse.data);
            } catch (error) {
                console.error('Fehler beim Laden der Profildaten:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const isAchievementUnlocked = (achievement) => {
        if (achievement.required_points > 0) {
            return userData.total_points >= achievement.required_points;
        }
        return achievement.unlocked;
    };

    if (loading) {
        return <div className = "loading-spinner" > Laden... < /div>;
    }

    return ( <
        div className = "app-container" >
        <
        div className = "profile-container" >
        <
        div className = "profile-header" >
        <
        div className = "profile-avatar" >
        <
        span className = "avatar-emoji" > { userData.username ? userData.username.charAt(0).toUpperCase() : '👤' } <
        /span> < /
        div > <
        div className = "profile-info" >
        <
        h2 > { userData.username } < /h2> <
        div className = "profile-stats" >
        <
        div className = "stat-item" >
        <
        span className = "stat-value" > { userData.total_points } < /span> <
        span className = "stat-label" > Punkte < /span> < /
        div > <
        div className = "stat-item" >
        <
        span className = "stat-value" > { achievements.filter(a => isAchievementUnlocked(a)).length } <
        /span> <
        span className = "stat-label" > Erfolge < /span> < /
        div > <
        /div> < /
        div > <
        /div>

        <
        div className = "achievements-section" >
        <
        h3 >
        <
        span className = "section-icon" > 🏆 < /span>
        Erfolge <
        /h3> <
        div className = "achievements-grid" > {
            achievements.map((achievement) => {
                    const unlocked = isAchievementUnlocked(achievement);
                    return ( <
                        div key = { achievement.id }
                        className = { `achievement-card ${unlocked ? 'unlocked' : 'locked'}` } >
                        <
                        div className = "achievement-icon" > { unlocked ? '🚀' : '🔒' } <
                        /div> <
                        div className = "achievement-info" >
                        <
                        h4 > { achievement.name } < /h4> <
                        p > { achievement.description } < /p> {!unlocked && achievement.required_points > 0 && ( <
                        div className = "progress-container" >
                        <
                        div className = "progress-bar" >
                        <
                        div className = "progress"
                        style = {
                            {
                                width: `${Math.min(100, (userData.total_points / achievement.required_points) * 100)}%`
                            }
                        }
                        /> < /
                        div > <
                        span className = "progress-text" > { userData.total_points }
                        /{achievement.required_points} Punkte < /
                        span > <
                        /div>
                    )
                } <
                /div> < /
                div >
            );
        })
} <
/div> < /
div > <
    /div>

<
nav className = "bottom-nav" >
    <
    button className = "nav-item"
onClick = {
        () => navigate('/dashboard')
    } >
    <
    span className = "nav-icon" > 🏠 < /span> <
span className = "nav-text" > Home < /span> < /
button > <
    button className = "nav-item"
onClick = {
        () => navigate('/leaderboard')
    } >
    <
    span className = "nav-icon" > 🏆 < /span> <
span className = "nav-text" > Bestenliste < /span> < /
button > <
    button className = "nav-item active" >
    <
    span className = "nav-icon" > 👤 < /span> <
span className = "nav-text" > Profil < /span> < /
button > <
    button className = "nav-item"
onClick = {
        () => {
            localStorage.removeItem('token');
            navigate('/');
        }
    } >
    <
    span className = "nav-icon" > 🚪 < /span> <
span className = "nav-text" > Logout < /span> < /
button > <
    /nav> < /
div >
);
}

export default Profile;