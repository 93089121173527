import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Leaderboard() {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLeaderboardData = async() => {
            try {
                const response = await axios.get('https://blemds.dev/api/leaderboard');
                setLeaderboardData(response.data);
            } catch (error) {
                console.error('Fehler beim Laden der Bestenliste:', error);
            }
        };

        fetchLeaderboardData();
    }, []);

    const renderTopThree = () => {
        return leaderboardData.slice(0, 3).map((player, index) => ( <
            div key = { player.id }
            className = "podium-item" >
            <
            div className = "podium-rank" > { index === 0 ? '👑' : index === 1 ? '🥈' : '🥉' } <
            /div> <
            div className = "podium-avatar" > { player.username.charAt(0).toUpperCase() } <
            /div> <
            div className = "podium-name" > { player.username } < /div> <
            div className = "podium-points" > { player.total_points }
            Punkte <
            /div> < /
            div >
        ));
    };

    const renderRestOfList = () => {
        return leaderboardData.slice(3).map((player, index) => ( <
            li key = { player.id }
            className = "leaderboard-item" >
            <
            div className = "rank" > { index + 4 } < /div> <
            div className = "player-info" >
            <
            div className = "player-avatar" > { player.username.charAt(0).toUpperCase() } <
            /div> <
            span className = "player-name" > { player.username } < /span> < /
            div > <
            div className = "player-points" > { player.total_points }
            Punkte <
            /div> < /
            li >
        ));
    };

    return ( <
        div className = "app-container" >
        <
        div className = "leaderboard-container" >
        <
        div className = "leaderboard-header" >
        <
        h2 > 🏆Bestenliste < /h2> < /
        div >

        <
        div className = "top-three-container" > {
            renderTopThree()
        } <
        /div>

        <
        ul className = "leaderboard-list" > {
            renderRestOfList()
        } <
        /ul> < /
        div >

        <
        nav className = "bottom-nav" >
        <
        button className = "nav-item"
        onClick = {
            () => navigate('/dashboard')
        } >
        <
        span className = "nav-icon" > 🏠 < /span> <
        span className = "nav-text" > Home < /span> < /
        button > <
        button className = "nav-item active" >
        <
        span className = "nav-icon" > 🏆 < /span> <
        span className = "nav-text" > Bestenliste < /span> < /
        button > <
        button className = "nav-item"
        onClick = {
            () => navigate('/profile')
        } >
        <
        span className = "nav-icon" > 👤 < /span> <
        span className = "nav-text" > Profil < /span> < /
        button > <
        button className = "nav-item"
        onClick = {
            () => {
                localStorage.removeItem('token');
                navigate('/');
            }
        } >
        <
        span className = "nav-icon" > 🚪 < /span> <
        span className = "nav-text" > Logout < /span> < /
        button > <
        /nav> < /
        div >
    );
}

export default Leaderboard;