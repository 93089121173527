import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Dashboard() {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userPoints, setUserPoints] = useState(0);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decoded = JSON.parse(atob(token.split('.')[1]));
                    setUsername(decoded.username);

                    // Kategorien abrufen
                    const categoriesResponse = await axios.get('https://blemds.dev/api/categories', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setCategories(categoriesResponse.data);

                    // Benutzerpunkte abrufen
                    const userResponse = await axios.get(`https://blemds.dev/api/user/${decoded.id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setUserPoints(userResponse.data.total_points);
                }
            } catch (error) {
                console.error('Fehler beim Laden der Daten:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return ( <
        div className = "app-container" >
        <
        div className = "top-bar" >
        <
        div className = "user-info" >
        <
        span className = "avatar" > 👤 < /span> <
        span className = "username" > { username } < /span> <
        span className = "points" > 🏆{ userPoints }
        Punkte < /span> < /
        div > <
        /div>

        <
        div className = "main-content" >
        <
        div className = "categories-grid" > {
            categories.map((category) => ( <
                div key = { category.id }
                className = "category-card"
                onClick = {
                    () => navigate(`/quiz/${category.id}`)
                } >
                <
                div className = "card-content" >
                <
                img src = { category.image_url }
                alt = { category.name }
                onError = {
                    (e) => {
                        e.target.src = 'https://via.placeholder.com/150?text=Anatomie';
                    }
                }
                /> <
                h3 > { category.name } < /h3> < /
                div > <
                /div>
            ))
        } <
        /div> < /
        div >

        <
        nav className = "bottom-nav" >
        <
        button className = "nav-item"
        onClick = {
            () => navigate('/dashboard')
        } >
        <
        span className = "nav-icon" > 🏠 < /span> <
        span className = "nav-text" > Home < /span> < /
        button > <
        button className = "nav-item"
        onClick = {
            () => navigate('/leaderboard')
        } >
        <
        span className = "nav-icon" > 🏆 < /span> <
        span className = "nav-text" > Bestenliste < /span> < /
        button > <
        button className = "nav-item"
        onClick = {
            () => navigate('/profile')
        } >
        <
        span className = "nav-icon" > 👤 < /span> <
        span className = "nav-text" > Profil < /span> < /
        button > <
        button className = "nav-item"
        onClick = {
            () => {
                localStorage.removeItem('token');
                navigate('/');
            }
        } >
        <
        span className = "nav-icon" > 🚪 < /span> <
        span className = "nav-text" > Logout < /span> < /
        button > <
        /nav> < /
        div >
    );
}

export default Dashboard;