import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Quiz() {
    const { categoryId } = useParams();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = JSON.parse(atob(token.split('.')[1]));
            setUserId(decoded.id);
        }

        const fetchQuestions = async() => {
            try {
                const response = await axios.get(`https://blemds.dev/api/questions/${categoryId}`);
                const formattedQuestions = response.data.map(question => ({
                    ...question,
                    answers: question.answers.split(','),
                    correct_answers: question.correct_answers.split(',').map(val => val === '1')
                }));
                setQuestions(formattedQuestions);
                setLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der Fragen:', error);
                setLoading(false);
            }
        };

        fetchQuestions();
    }, [categoryId]);

    const updateUserScore = async(points) => {
        try {
            await axios.post('https://blemds.dev/api/update-score', {
                userId: userId,
                points: points
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Punktzahl:', error);
        }
    };

    const handleAnswerClick = async(answerIndex) => {
        if (selectedAnswer !== null) return;

        setSelectedAnswer(answerIndex);
        const currentQuestion = questions[currentQuestionIndex];
        const isCorrect = currentQuestion.correct_answers[answerIndex];

        // Punkteaktualisierung
        if (isCorrect) {
            await updateUserScore(1); // +1 Punkt für richtige Antwort
            setScore(prevScore => prevScore + 1);
        } else {
            await updateUserScore(-1); // -1 Punkt für falsche Antwort
            setScore(prevScore => prevScore - 1);
        }

        setTimeout(() => {
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setSelectedAnswer(null);
            } else {
                setShowResult(true);
            }
        }, 1500);
    };

    const restartQuiz = () => {
        setCurrentQuestionIndex(0);
        setScore(0);
        setShowResult(false);
        setSelectedAnswer(null);
    };

    if (loading) {
        return <div className = "container" > Laden... < /div>;
    }

    if (questions.length === 0) {
        return ( <
            div className = "container" >
            <
            h2 > Keine Fragen verfügbar < /h2> <
            button className = "button"
            onClick = {
                () => navigate('/dashboard')
            } >
            Zurück zum Dashboard <
            /button> < /
            div >
        );
    }

    if (showResult) {
        return ( <
            div className = "quiz-completion" >
            <
            div className = "completion-header" >
            <
            h2 className = "completion-title" > Quiz beendet! < /h2> <
            p className = "completion-subtitle" > {
                score > (questions.length / 2) ?
                "Großartige Leistung! 🎉" : "Weiter üben! 💪"
            } <
            /p> < /
            div >

            <
            div className = "score-display" >
            <
            div className = "score-circle" >
            <
            span className = "score-number" > { score } < /span> < /
            div > <
            /div>

            <
            div className = "stats-grid" >
            <
            div className = "stat-card" >
            <
            div className = "stat-value" > { score } < /div> <
            div className = "stat-label" > Punkte < /div> < /
            div > <
            div className = "stat-card" >
            <
            div className = "stat-value" > { questions.length } < /div> <
            div className = "stat-label" > Fragen < /div> < /
            div > <
            div className = "stat-card" >
            <
            div className = "stat-value" > { Math.round((score / questions.length) * 100) } % < /div> <
            div className = "stat-label" > Erfolgsquote < /div> < /
            div > <
            /div>

            <
            div className = "completion-message" > {
                score > (questions.length / 2) ?
                "Großartige Leistung! Weiter so!" : "Nicht aufgeben! Übe weiter und verbessere dich!"
            } <
            /div>

            <
            div className = "completion-buttons" >
            <
            button className = "completion-button primary"
            onClick = {
                () => window.location.reload()
            } >
            Noch einmal spielen <
            /button> <
            button className = "completion-button secondary"
            onClick = {
                () => navigate('/dashboard')
            } >
            Zum Dashboard <
            /button> < /
            div > <
            /div>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    return ( <
        div className = "container" >
        <
        div className = "quiz-header" >
        <
        h2 > Frage { currentQuestionIndex + 1 }
        von { questions.length } < /h2> <
        p > Punktestand: { score } < /p> < /
        div >

        <
        div className = "quiz-content" > {
            currentQuestion.image_url && ( <
                img src = { currentQuestion.image_url }
                alt = "Quiz Frage"
                className = "quiz-image"
                onError = {
                    (e) => {
                        e.target.src = 'https://via.placeholder.com/400x300?text=Anatomie';
                    }
                }
                />
            )
        }

        <
        div className = "quiz-options" > {
            currentQuestion.answers.map((answer, index) => ( <
                button key = { index }
                className = { `quiz-option ${
                selectedAnswer === index 
                  ? currentQuestion.correct_answers[index]
                    ? 'correct'
                    : 'incorrect'
                  : selectedAnswer !== null && currentQuestion.correct_answers[index]
                    ? 'correct'
                    : ''
              }` }
                onClick = {
                    () => handleAnswerClick(index)
                }
                disabled = { selectedAnswer !== null } > { <
                    div className = "answer-content" >
                    <
                    span className = "answer-number" > { String.fromCharCode(65 + index) } < /span> <
                    span className = "answer-text" > { answer } < /span> < /
                    div >
                } <
                /button>
            ))
        } <
        /div> < /
        div > <
        /div>
    );
}

export default Quiz;