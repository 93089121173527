import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Quiz from './components/Quiz';
import Leaderboard from './components/Leaderboard';
import Profile from './components/Profile';

function App() {
    const isAuthenticated = () => {
        return localStorage.getItem('token') !== null;
    };

    return ( <
        Router >
        <
        Routes >
        <
        Route path = "/"
        element = { < Login / > }
        /> <
        Route path = "/register"
        element = { < Register / > }
        /> <
        Route path = "/dashboard"
        element = { isAuthenticated() ? < Dashboard / > : < Navigate to = "/" / > }
        /> <
        Route path = "/quiz/:categoryId"
        element = { isAuthenticated() ? < Quiz / > : < Navigate to = "/" / > }
        /> <
        Route path = "/leaderboard"
        element = { isAuthenticated() ? < Leaderboard / > : < Navigate to = "/" / > }
        /> <
        Route path = "/profile"
        element = { isAuthenticated() ? < Profile / > : < Navigate to = "/" / > }
        /> < /
        Routes > <
        /Router>
    );
}

export default App;